import * as React from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { translateError } from '../../utils/errors'

export interface ErrorAlertProps {
  errorCause: string | unknown
  testId?: string
}
const SUPPORT_LINK = 'https://central.toasttab.com/s/'

const ContactSupportLink = React.memo(() => (
  <>
    Please{' '}
    <a
      className='text-link hover:text-link-hover underline'
      href={SUPPORT_LINK}
      target='_blank'
      rel='noreferrer'
    >
      contact support
    </a>
    .
  </>
))

export const ErrorAlert = ({
  testId = 'api-error',
  errorCause
}: ErrorAlertProps) => {
  const { message, isExpected } = translateError(String(errorCause))
  return (
    <Alert variant='error' showIcon testId={testId}>
      {message} {!isExpected && <ContactSupportLink />}
    </Alert>
  )
}
