import { AxiosError } from 'axios'
export interface ToastwebError {
  errorCause: string
}

export const ErrorsMap = Object.freeze({
  INVALID_PASSWORD: 'This password is invalid. Please try another one.',
  SAME_PASSWORD:
    'This password is identical to a password you have used in the past.'
})

export const UnexpectedErrorsMap = Object.freeze({
  RECOVER_PASSWORD_FAILED: 'An error occurred updating your password.',
  UNEXPECTED_ERROR: 'Sorry! Something went wrong.'
})

const buildError = <T extends typeof ErrorsMap | typeof UnexpectedErrorsMap>(
  error: string,
  source: T,
  isExpected: boolean
) => ({
  message: source[error as keyof T],
  isExpected
})

export const translateError = (error: string) => {
  if (error in ErrorsMap) {
    return buildError(error, ErrorsMap, true)
  }
  if (error in UnexpectedErrorsMap) {
    return buildError(error, UnexpectedErrorsMap, false)
  }
  return buildError('UNEXPECTED_ERROR', UnexpectedErrorsMap, false)
}

export function handleBackendErrors<T>(apiCall: Promise<T>) {
  return apiCall.catch((e: AxiosError<ToastwebError>) => {
    const cause = e.response?.data.errorCause
    throw new Error(cause ?? 'UNEXPECTED_ERROR')
  })
}
