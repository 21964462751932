import React, { ReactNode } from 'react'
import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'

export const Title = ({ children }: { children: ReactNode }) => (
  <h1 className='type-headline-4 md:type-headline-2'>{children}</h1>
)
export const Subtitle = ({ children }: { children: ReactNode }) => (
  <h3 className='type-large md:type-default'>{children}</h3>
)
export const Separator = () => (
  <hr className='bg-gray-125 border-2 border-transparent' />
)

export const Header = ({ children }: { children: ReactNode }) => (
  <header className='grid grid-cols-1 gap-4 md:gap-6'>
    <ToastBrand size='xxs' className='mb-2 md:mb-4' />
    <div className='grid grid-cols-1 gap-4'>{children}</div>
    <Separator />
  </header>
)
