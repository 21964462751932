import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Forgot } from '../Forms/Forgot'
import { Reset } from '../Forms/Reset'

export const App = () => (
  <Routes>
    <Route path='/reset-password' element={<Forgot />} />
    <Route path='/recover-password' element={<Reset />} />
    {/* 404 not found, use @toasttab/buffet-pui-error-pages */}
  </Routes>
)
