import React, { useState } from 'react'
import { useSentry } from 'banquet-runtime-modules'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import { useMutation } from 'react-query'
import { isValidEmail } from '@toasttab/email-validation'
import {
  TextInputField,
  CancelButton,
  SubmitButton
} from '@toasttab/buffet-pui-forms'
import { ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { useHeap } from '@toasttab/use-heap'
import { Layout, Grid, Header, Title, Subtitle } from '@local/layout'
import { useRouterQueryParams } from '@local/use-query-params'
import { LoginApplication, redirectToLogin } from '../../../utils/login'
import { forgotPassword, ForgotPasswordVariables } from '../../../api/password'
import { Keys } from '../../../api/keys'
import { ErrorAlert } from '../../Error'
import { useHideBackButton } from '../../../hooks/useHideBackButton'

interface ForgotFormProps {
  onSubmit: (values: { email: string }) => Promise<any>
  onCancel: () => void
}

const ForgotForm = ({ onSubmit, onCancel }: ForgotFormProps) => {
  const hideBackButton = useHideBackButton()
  const { redactAttributes } = useHeap()

  const schema = object().shape({
    email: string()
      .required(
        'An email address is required to send you a link and reset your password.'
      )
      .test({
        name: 'isValid',
        message: 'This email address is invalid.',
        test: (email?: string) => isValidEmail(email)
      })
  })

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmit}
      onReset={onCancel}
      validationSchema={schema}
      validateOnBlur
      validateOnChange
    >
      {({ isValid, dirty }) => (
        // mb-20: Account for buttons space on mobile
        <Form
          className='mb-20 grid grid-cols-1 gap-6 md:mb-0'
          aria-labelledby='forgot-password-title'
        >
          <TextInputField
            name='email'
            placeholder={'Email address'}
            label={'Email address'}
            type='email'
            {...redactAttributes(['value'])}
          />
          <ButtonGroup className='fixed bottom-0 left-0 w-full bg-white p-4 md:static md:p-0'>
            {!hideBackButton && (
              <CancelButton className='w-1/2 min-w-fit'>
                Back to log in
              </CancelButton>
            )}
            <SubmitButton className='w-1/2' disabled={!isValid || !dirty}>
              Reset
            </SubmitButton>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  )
}

const ForgotHeader = React.memo(() => (
  <Header>
    <Title>Forgot password</Title>
    <Subtitle>
      Enter the email associated with your account and we’ll send an email with
      instructions to reset your password.
    </Subtitle>
  </Header>
))

const CheckEmailHeader = React.memo(({ email }: { email: string }) => (
  <Header>
    <Title>Check your email</Title>
    <Subtitle>
      If an account exists for {email}, a password reset email has been sent.
    </Subtitle>
  </Header>
))

export const Forgot = () => {
  const { loginApplication = 'TOAST' } = useRouterQueryParams<{
    loginApplication: LoginApplication
  }>(['loginApplication'])
  const { captureException } = useSentry()

  const [email, setEmail] = useState<string>('')
  const { mutateAsync, isError, isSuccess, error } = useMutation<
    unknown,
    Error,
    ForgotPasswordVariables,
    unknown
  >(Keys.FORGOT_PASSWORD, forgotPassword, {
    onSuccess(_, { email }) {
      setEmail(email)
    }
  })

  return (
    <Layout>
      <Grid>
        {isSuccess ? (
          <CheckEmailHeader email={email} />
        ) : (
          <>
            <ForgotHeader />
            {isError && (
              <ErrorAlert errorCause={error?.message} testId='failed-forgot' />
            )}
            <ForgotForm
              onSubmit={({ email }) => {
                return mutateAsync({
                  email,
                  loginApplication,
                  captureException
                })
              }}
              // TODO: [MU-2529] May change with application
              // see spa-user-activation login.js
              onCancel={() => redirectToLogin(loginApplication)}
            />
          </>
        )}
      </Grid>
    </Layout>
  )
}
