import { useRouterQueryParams } from '@local/use-query-params'

/**
 * Build the response for useReturnUrl.
 * @param queryParams
 * Valid query parameters to be injected to the login page redirection.
 * @param decodedReturnUrl
 * Decoded returnUrl from the query parameters.
 */
const buildResponse = (
  queryParams: URLSearchParams,
  decodedReturnUrl: string | null = null
) => {
  return {
    /** To be appended to a platform login page (Payroll, Toastweb, etc.) */
    redirectUrl: queryParams.toString(),
    /** For tracking purposes */
    decodedReturnUrl
  }
}

/**
 * Checks if a `returnUrl` query parameter is present, and parse it before
 * getting it returned. A valid `returnUrl` should be relative, or absolutely
 * based on Toastweb origin for the current environment. It can also takes
 * default parameters to be appended to the result.
 * @param defaultParams
 * A map of query params that will be encoded in the redirectUrl.
 * @param onError
 * A function called when an error occurs in the process. Can be used to show
 * errors in the console, in the App, or register them in Sentry.
 * @returns
 * The redirectUrl for the redirection, and the decodedReturnUrl from the
 * original returnUrl query parameter.
 */
export const useReturnUrl = ({
  defaultParams,
  onError = () => null
}: {
  defaultParams?: Record<string, string>
  onError?: (error: string) => void
} = {}) => {
  const { returnUrl } = useRouterQueryParams<{ returnUrl: string }>([
    'returnUrl'
  ])
  const redirectionParams = new URLSearchParams(defaultParams)
  let decodedReturnUrl = ''

  if (!returnUrl) {
    return buildResponse(redirectionParams)
  }

  try {
    decodedReturnUrl = decodeURIComponent(returnUrl)

    try {
      const redirectURL = new URL(decodedReturnUrl)
      if (redirectURL.origin === window.location.origin) {
        redirectionParams.append('returnUrl', redirectURL.href)
      } else {
        onError(
          `[Invalid URL] returnUrl is absolute but origin is not Toastweb: ${decodedReturnUrl}`
        )
        decodedReturnUrl = ''
      }
    } catch (urlWithoutBaseError) {
      /**
       * URL constructor requires an absolute path, it should fail here if
       * `decodedReturnUrl` is relative.
       */

      try {
        const redirectURL = new URL(decodedReturnUrl, window.location.origin)
        redirectionParams.append('returnUrl', redirectURL.href)
      } catch (urlWithBaseError) {
        onError(
          `[Invalid URL] returnUrl is relative but malformed: ${decodedReturnUrl}`
        )
        decodedReturnUrl = ''
      }
    }
  } catch (decodingError) {
    onError(`[Invalid URL] returnUrl is malformed: ${decodedReturnUrl}`)
  }

  return buildResponse(redirectionParams, decodedReturnUrl || null)
}
