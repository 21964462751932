let cachedToken: string | undefined = undefined

/**
 * Gets the authenticity token from the page. The token can be cached in the
 * memory, skipping the step to find it in the document and returning it
 * directly, if it has been cached previously. When the token isn't found, it
 * will call the `onTokenNotFound` callback, if provided, to capture errors.
 * @param cache Whether the token should be retrieved from the cache or not.
 * Relies on the memory cache, so it will be clear after a page refresh.
 * Defaults to `false`.
 * @param onTokenNotFound A callback triggered when the token has not been found
 * on the page. Defaults to `undefined`.
 * @returns Returns the token if it has been found, or an empty string. This
 * method won't throw an exception on token not found (but the requested
 * endpoint should return an error).
 */
export const getAuthenticityToken = (
  cache = false,
  onTokenNotFound?: () => void
) => {
  if (cache && cachedToken) {
    return cachedToken
  }

  const node = document.getElementById(
    'authenticity-token'
  ) as HTMLInputElement | null

  if ((!node || !node?.value) && onTokenNotFound) {
    onTokenNotFound()
  }

  cachedToken = node?.value
  return node?.value ?? ''
}

const getSeparator = (url: string) => (url.includes('?') ? '&' : '?')

/**
 * Append the authenticity token to the requested URL as a query parameter. It
 * will automatically add the proper query parameter separator according to
 * the presence or not of other parameters in the provided URL.
 * @param url The URL to reach. The authenticity token should only be needed for
 * non-safe HTTP verbs (`PUT`, `POST`, `DELETE`, `PATCH`).
 * @param cache Whether the token should be retrieved from the cache or not.
 * Relies on the memory cache, so it will be clear after a page refresh.
 * Defaults to `false`.
 * @param onTokenNotFound A callback triggered when the token has not been found
 * on the page. Defaults to `undefined`.
 * @returns The provided URL appended with the authenticity token.
 */
export const addAuthenticityTokenInUrl = (
  url: string,
  cache = false,
  onTokenNotFound?: () => void
) =>
  `${url}${getSeparator(url)}authenticityToken=${getAuthenticityToken(
    cache,
    onTokenNotFound
  )}`
