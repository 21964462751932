import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { App } from './App'

const queryClient = new QueryClient()

export const AppBootstrap = () => (
  <BrowserRouter basename='/account'>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>
)
