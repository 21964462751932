import { useQueryParams } from '@local/use-query-params'

const caseInsensitiveCompare = (a: string, b: string) => {
  return (
    a.localeCompare(b, undefined, {
      sensitivity: 'accent'
    }) === 0
  )
}

export const useHideBackButton = () => {
  const { loginApplication = 'toast', hideBackButton = 'false' } =
    useQueryParams<{
      loginApplication: string
      hideBackButton: string
    }>(['loginApplication', 'hideBackButton'])
  return (
    caseInsensitiveCompare(loginApplication, 'XTRACHEF') &&
    caseInsensitiveCompare(hideBackButton, 'true')
  )
}
