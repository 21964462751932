import React from 'react'
import { useSentry } from 'banquet-runtime-modules'
import { Formik, Form } from 'formik'
import { useMutation } from 'react-query'
import {
  PasswordFieldWithRequirements,
  CancelButton,
  SubmitButton
} from '@toasttab/buffet-pui-forms'
import { ButtonGroup, Button } from '@toasttab/buffet-pui-buttons'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useHeap } from '@toasttab/use-heap'
import { Layout, Grid, Header, Title, Subtitle } from '@local/layout'
import { useReturnUrl } from '@local/use-return-url'
import { useRouterQueryParams } from '@local/use-query-params'
import { formatLoginUrlWithParams } from '../../../utils/formatLoginUrlWithParams'
import { LoginApplication, redirectToLogin } from '../../../utils/login'
import { resetPassword, ResetPasswordVariables } from '../../../api/password'
import { Keys } from '../../../api/keys'
import { ErrorAlert } from '../../Error'
interface ResetFormProps {
  onSubmit: (values: { password: string }) => Promise<any>
  onCancel: () => void
}

const ResetForm = ({ onSubmit, onCancel }: ResetFormProps) => {
  const { redactAttributes } = useHeap()

  return (
    <Formik
      initialValues={{ password: '' }}
      onSubmit={onSubmit}
      onReset={onCancel}
      validateOnBlur
      validateOnChange
    >
      {({ isValid, dirty }) => (
        // mb-20: Account for buttons space on mobile
        <Form
          className='mb-20 grid grid-cols-1 gap-6 md:mb-0'
          aria-labelledby='reset-password-title'
        >
          <PasswordFieldWithRequirements
            name='password'
            placeholder='Password'
            label='Create new password'
            {...redactAttributes(['value'])}
          />
          <ButtonGroup className='fixed bottom-0 left-0 w-full bg-white p-4 md:static md:p-0'>
            <CancelButton className='w-1/2'>Cancel</CancelButton>
            <SubmitButton
              className='w-1/2 min-w-fit'
              disabled={!isValid || !dirty}
            >
              Update password
            </SubmitButton>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  )
}

const SuccessfulBody = React.memo(({ loginLink }: { loginLink: string }) => (
  <>
    <Alert variant='success' showIcon testId='successful-reset'>
      Password updated! Use your new password to log in.
    </Alert>
    <Button as='a' href={loginLink} variant='primary'>
      Log in
    </Button>
  </>
))

export const Reset = () => {
  const { captureException } = useSentry()
  const { email, loginApplication = 'TOAST' } = useRouterQueryParams<{
    email: string
    loginApplication: LoginApplication
  }>(['email', 'loginApplication'])
  const { redirectUrl } = useReturnUrl({
    defaultParams: email ? { email: email.toString() } : {},
    onError(e) {
      captureException(new Error(e))
    }
  })

  const {
    mutateAsync,
    data: loginUrl,
    error,
    isSuccess,
    isError
  } = useMutation<string, Error, ResetPasswordVariables, unknown>(
    Keys.RESET_PASSWORD,
    resetPassword
  )

  return (
    <Layout>
      <Grid>
        <Header>
          <Title>Reset password</Title>
          <Subtitle>
            Your new password must be different from previously used passwords.
          </Subtitle>
        </Header>
        {isSuccess ? (
          <SuccessfulBody
            loginLink={formatLoginUrlWithParams(loginUrl, redirectUrl)}
          />
        ) : (
          <>
            {isError && (
              <ErrorAlert errorCause={error?.message} testId='failed-reset' />
            )}
            <ResetForm
              onSubmit={({ password }) => {
                return mutateAsync({ password, captureException })
              }}
              onCancel={() =>
                redirectToLogin(loginApplication as LoginApplication)
              }
            />
          </>
        )}
      </Grid>
    </Layout>
  )
}
