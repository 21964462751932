import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import { AppBootstrap } from './components/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-afp',
  rootComponent: AppBootstrap,
  sentry: {
    publicKey: '107d0dd53882402288e0a52ecf6fd57d@o37442',
    projectId: '5843092',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'spa-account-forgot-password'
