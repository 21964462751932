import React, { ReactNode } from 'react'
import { MarketingCarousel } from '@toasttab/buffet-patterns-marketing-carousel'

interface ChildrenProps {
  children: ReactNode
}
interface TestIdProps {
  testId?: string
}

export const Grid = ({ children }: ChildrenProps) => (
  <div className='grid grid-cols-1 gap-6'>{children}</div>
)

export const Layout = ({
  children,
  testId = 'layout'
}: ChildrenProps & TestIdProps) => (
  <div
    data-testid={testId}
    className='bg-gray-25 flex min-h-screen lg:flex-row'
  >
    {/* Hidden on mobile (on portrait/landscape), and tablet (on portrait) */}
    <div className='hidden lg:block lg:w-3/5'>
      <MarketingCarousel />
    </div>
    <div className='flex w-full flex-row justify-center bg-white md:rounded md:shadow lg:w-2/5'>
      <div className='xxl:mx-36 mx-4 my-10 flex-1 md:mx-12 lg:mx-20 lg:mt-64 xl:mx-28'>
        {children}
      </div>
    </div>
  </div>
)
