import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

export type LoginApplication = 'TOAST' | 'PAYROLL' | 'XTRACHEF'

// Ideally, that should be in a publishable package, or given by the back-end.
// The advantage of such package is that we have a centralized source of truth
// that any SPA on any platform could be using. I'll note that as a _futurely_
// abandoned but good Flex friday idea.
function getPayrollLoginUrl() {
  switch (getCurrentEnvironment()) {
    case ToastEnvironment.DEV:
      return 'https://payroll.eng.toastteam.com/CompanyCode'
    case ToastEnvironment.PROD:
      return 'https://payroll.toasttab.com/CompanyCode'
    case ToastEnvironment.PREPROD:
    default:
      // Payroll doesn't have a sandbox env
      return 'https://payroll.preprod.eng.toasttab.com/CompanyCode'
  }
}

// Xtrachef integration should mat to toast preprod
function getXtraChefLoginUrl() {
  switch (getCurrentEnvironment()) {
    case ToastEnvironment.DEV:
      return 'https://bpo.xtrachef.com/XtraChefAccount/Account/LogOn'
    case ToastEnvironment.PROD:
      return 'https://app.xtrachef.com/XtraChefAccount/Account/LogOn'
    case ToastEnvironment.PREPROD:
      return 'https://preprod.xtrachef.com/XtraChefAccount/Account/LogOn'
    default:
      return 'https://preprod.xtrachef.com/XtraChefAccount/Account/LogOn'
  }
}

// The page is hosted on Toastweb, no need to provide the origin
function getToastwebLoginUrl() {
  return '/login'
}

export function getLoginUrl(application: LoginApplication = 'TOAST') {
  if (application === 'PAYROLL') {
    return getPayrollLoginUrl()
  }
  if (application === 'XTRACHEF') {
    return getXtraChefLoginUrl()
  }
  return getToastwebLoginUrl()
}

export function redirectToLogin(application: LoginApplication = 'TOAST') {
  window.location.assign(getLoginUrl(application))
}
