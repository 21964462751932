import axios from 'axios'

import { addAuthenticityTokenInUrl } from '@local/authenticity-token'
import { handleBackendErrors } from '../utils/errors'
import { BASE_USERS_URI, BASE_TOASTWEB_URI } from './constants'

export interface ForgotPasswordVariables {
  email: string
  loginApplication: string
  captureException: (err: Error) => void
}

export interface ResetPasswordVariables {
  password: string
  captureException: (err: Error) => void
}

const authenticityTokenHandler = (
  url: string,
  captureException: (err: Error) => void
) => {
  return addAuthenticityTokenInUrl(url, false, () =>
    captureException(new Error('Authenticity token not found'))
  )
}

export const forgotPassword = ({
  email,
  loginApplication,
  captureException
}: ForgotPasswordVariables) =>
  handleBackendErrors(
    axios.post(
      authenticityTokenHandler(
        BASE_USERS_URI +
          `/reset-password/email/${email}?loginApplication=${loginApplication.toUpperCase()}`,
        captureException
      )
    )
  )

export const resetPassword = ({
  password,
  captureException
}: ResetPasswordVariables) =>
  handleBackendErrors(
    axios
      .post<string>(
        authenticityTokenHandler(
          BASE_TOASTWEB_URI + `/recover-password`,
          captureException
        ),
        { password }
      )
      .then(({ data }) => data)
  )
